<template>
  <div class="answer">
    <div class="answer__h2">
      <img src="../../../assets/h4.png" />
    </div>
    <ul class="answer__list">
      <li class="answer__item">
        <el-card class="box-card" shadow="never" >
          <div slot="header" class="clearfix">
            <span :style="{ fontSize: '20px', fontWeight: 'bold' }">购买相关课程后在哪里观看学习？</span>
            <el-button style="float: right; padding: 3px 0"  round
              >更多</el-button
            >
          
          </div>
          <div  class="text item">
            1.使用电脑学习，登陆www.wangxiao.cn，进入课程学习中心开始学习。

2.通过移动设备学习，如手机、iPad、智能盒子等，需要下载“中大网校”APP（安卓-IPhone皆有客户端）或者准题库APP到设备，登陆后在我的课程中开始学习；（会有专属班主任进行全程指导）
          </div>
        </el-card>
      </li>
      <li class="answer__item">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span :style="{ fontSize: '20px', fontWeight: 'bold' }">如何签署培训协议？</span>
            <el-button style="float: right; padding: 3px 0" round
              >更多</el-button
            >
          </div>
          <div  class="text item">
            1.您提供您的邮寄地址及收货信息，由您的报课老师为您将纸质版的通关协议邮寄给您，签字后回寄给相关老师；

2.由中大网校盖章之后，合同即可生效。之后给您邮寄生效的培训协议。协议自签订之日生效，在成绩查询之后，如有学员未通过考试，可以根据协议内容进行退费保障。
          </div>
        </el-card>
      </li>
      <li class="answer__item">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span :style="{ fontSize: '20px', fontWeight: 'bold' }">购买课程以后可以下载吗？</span>
            <el-button style="float: right; padding: 3px 0" round
              >更多</el-button
            >
          </div>
          <div  class="text item">
            课程视频、练习、讲义、特训资料、临考密押等相关内容可通过“中大网校”APP移动应用下载到智能手机/平板电脑学习。每位学员可在手机、平板电脑上下载认证，断网后可在认证过得设备上看课。
          </div>
        </el-card>
      </li>
      <li class="answer__item">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span :style="{ fontSize: '20px', fontWeight: 'bold' }">如何享受第二考季免费续学服务？</span>
            <el-button style="float: right; padding: 3px 0" round
              >更多</el-button
            >
          </div>
          <div  class="text item">
            报名班型包含重学服务或者签署退费协议的学员，需在其报考所在地区考试分数查询方式公布之后内向班主任老师提供成绩查询截图，提出申请并审核通过后即可享受第二年免费重学或者退费协议。
          </div>
        </el-card>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.answer {
  width: 100%;
  height: 65vh;
  background: url(data:image/gif;base64,R0lGODdhAQABAPAAAMPDwwAAACwAAAAAAQABAAACAkQBADs=)
    top center;
  background-color: #f7f7f7;
  padding: 10px 15%;
  box-sizing: border-box;
  background-image: url(../../../assets/bg1.png);
  .answer__list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .answer__item {
      list-style: none;
      width: 50%;
      height: 23vh;
      padding: 20px 1%;
    box-sizing: border-box;
    .box-card{
        border-radius: 10px;
        .clearfix {
      width: 100%;
      display: flex;
      justify-content: space-between;
      button{
        width: 50px;
        border: 1px solid #F56C6C;
        color: #F56C6C;
      }
      button:hover{
        background-color: #F56C6C;
        color: white;
      }
    }

    button{
      color: #6e6e6e;
    }
    }
    .text {
        font-size: 14px;
    }
    }
  }
}
</style>>