<template>
  <div class="top">
    <div class="top1">
      <img src="../../../assets/sc_logo.jpg" alt="" />
    </div>
    <div class="top2">
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input2"
      >
        <template slot="suffix">
          <el-button type="danger" round>搜索</el-button>
        </template>
      </el-input>
    </div>
    <div class="top3">
      <el-button
        type="text"
        :class="activeIndex == 1 ? active : ''"
        @click="onclick(1)"
        >首页</el-button
      >
      <el-button
        type="text"
        :class="activeIndex == 2 ? active : ''"
        @click="onclick(2)"
        >课程</el-button
      >
      <el-button type="text" @click="onclick(3)">题库</el-button>
      <el-button type="text" @click="onclick(4)">资料</el-button>
    </div>
    <div class="top4">
      <el-button type="danger" plain round @click="login" v-show="isLogin"
        >登录/注册</el-button
      >
      <el-dropdown  @command="handleCommand">
      <img src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" v-show="!isLogin" class="el-dropdown-link">
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {localStorageUtil} from "../../../LocalStorageUtil"
export default {
  data() {
    return {
      active: "active",
    };
  },
  methods: {
    onclick(index) {
      if (index == 1) {
        this.$router.replace({ name: "Index" });
      } else if (index == 2) {
        this.$router.replace({ name: "Courses" });
      } else if (index == 3) {
        // this.$router.replace({ name: "Result" });
      } else {
        // this.$router.replace({ name: "Book" });
      }
    },
    login(){
      this.$store.commit("changeDialog",true)
    },
    handleCommand(){
      localStorage.removeItem('token');
    // 刷新页面
    location.reload();
    console.log(111)
    }
  },
  beforeCreate() {
    console.log(this.$router);
    console.log(localStorageUtil.readExpire("token"));
  },
  computed: {
    ...mapState(["activeIndex"]),
    isLogin() {
      const token = localStorageUtil.readExpire("token");
    return !token; // 如果 token 存在，则返回 true；否则返回 false
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  height: 13vh;
  background-color: #fff;
  position: sticky;
  top: 0;
  padding: 0 15%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  z-index: 1000;
  .top1 {
    width: 10%;
    height: 100%;
    margin-right: 5%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .top2 {
    width: 40%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    //使用 /deep/
    .el-input /deep/ .el-input__inner {
      background-color: rgb(255 255 255);
      border-radius: 20px;
    }
    /deep/.el-input__inner:focus {
      // el-input输入时设置边框颜色
      border-color: #f56c6c;
    }
    .el-input /deep/ .el-input__suffix {
      right: 0 !important;
    }
  }
  .top3 {
    width: 30%;
    display: flex;
    justify-content: space-around; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .el-button--text {
      color: #6e6e6e;
      font-size: 16px;
      font-weight: 700;
      position: relative;
    }
    .active:before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 24px;
      height: 4px;
      background: #ea5f59;
      border-radius: 2px;
    }
  }
  .top4 {
    display: flex;
    align-items: center;
    justify-content: right;
    .el-button {
      border-color: #f56c6c;
    }
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  }
}
</style>