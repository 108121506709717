export let localStorageUtil = {
    /**
     * 写入localStorage
     * @param key    key
     * @param value  value
     * @param expire 超时时间（以秒为单位）
     */
    writeExpire: function (key, value, expire) {
        let obj = {
            time: new Date().getTime(),
            data: value,
            expire: expire,
        };
        let objStr = JSON.stringify(obj);
        localStorage.setItem(key, objStr);
    },
 
    /**
     * 读出localStorage
     */
    readExpire: function (key) {
        let value = localStorage.getItem(key);
        if (!value || value === "null") {
            return value;
        }
 
        value = JSON.parse(value);
        if (Date.now() - value.time > value.expire * 1000) {
            localStorage.removeItem(key);
            return null;
        }
 
        return value.data;
    }
}
 
// export default localStorageUtil;