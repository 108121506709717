<template>
  <div class="block">
    <el-carousel trigger="click" height="150px">
      <el-carousel-item v-for="(item,index) in photos" :key="index">
        <img :src="getImageUrl(item.image_url)" />
      </el-carousel-item>
    </el-carousel>
    <div class="menu" @mouseleave="handleMouseLeave">
      <ul class="left_menu" :style="{ borderRadius: '10px 0 0 10px' }">
        <li
          class="menu_box"
          v-for="(outerItem, outerIndex) in menu_data"
          :key="outerIndex"
          @mouseover="showContent(outerItem)"
        >
          <div class="box1">{{ outerItem.classify1 }}</div>
          <div class="box2">
            <div
              v-for="(innerItem, innerIndex) in outerItem.children"
              :key="innerIndex"
            >
              <span v-if="innerIndex < 3"
                >{{ innerItem.classify2 }}&nbsp;&nbsp;
              </span>
            </div>
          </div>
        </li>
      </ul>
      <div class="right_content" v-if="contentHovered">
        <div class="title">{{ currentContent.classify1 }}</div>
        <div class="classifys">
          <div
            class="classify"
            v-for="(item, index) in currentContent.children"
            :key="index"
            @click="goCouerse(item.id)"
          >
            {{ item.classify2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../../config'
export default {
  data() {
    return {
      menu_data: [],
      currentContent: null,
      contentHovered: false,
      api: config.domain + "/",
      photos:[]
    };
  },
  methods: {
    showContent(category) {
      this.currentContent = category;
      console.log(this.currentContent);
      this.contentHovered = true;
    },
    handleMouseLeave() {
      this.contentHovered = false;
    },
    goCouerse(id) {
      this.$router.replace({
        name: "Courses",
        query: { id: id },
      });
      // console.log(id)
    },
    getImageUrl(imagePath) {
      const api = this.api;
      return `${api}${imagePath}`;
    }, 
  },
  async created() {
      this.photos = await this.$axios("getbanner");
      // console.log(this.banners)
      let res=await this.$axios('get_courses')
      // console.log(res.result)
      this.menu_data=res.result.menu_data
    },
};
</script>

<style lang="less" scoped>
.block {
  width: 100%;
  position: relative;
  margin-top: 15px;
}
/deep/.el-carousel__container {
  width: 100%;
  height: 50vh !important;
  .el-carousel__item{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
    height: 100%;
    }
  }
}



.left_menu {
  width: 16%;
  height: 50vh;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 15%;
  background-color: #fff;
  overflow: scroll;
  background-color: #fff;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  // overflow-y: scroll;
  .menu_box {
    width: 100%;
    height: 15%;
    box-sizing: border-box;
    padding: 5px 5%;
    .box1 {
      display: flex;
      align-items: center;
      justify-items: left;
      color: #1a1a1a;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
    }
    .box2 {
      display: flex;
      align-items: center;
      justify-items: left;
      padding-top: 2px;

      div {
        color: #6e6e6e;
        font-size: 14px;
        cursor: pointer;
      }
      div:hover {
        color: #f56c6c;
      }
    }
  }
  .menu_box:hover {
    background-color: #f3f3f5;
  }
}
.right_content {
  height: 50vh;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 31%;
  background-color: #fff;
  .title {
    width: 10vw;
    height: 10%;
    font-weight: 700;
  }
  .classifys {
    height: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .classify {
      width: 10vw;
      height: 10%;
      box-sizing: border-box;
      color: #6e6e6e;
      font-size: 14px;
      cursor: pointer;
    }

    .classify:hover {
      color: #f56c6c;
    }
  }
}
li {
  list-style-type: none;
}
</style>