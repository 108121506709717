<template>
  <div class="bottom">
    <ul>
      <li>
        <div
          class="icon"
          :style="{
            background:
              'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/01.png) no-repeat',
          }"
        ></div>
        <div class="text">
          <p class="h1">业内老师</p>
          <p class="h2">行业名气讲师领学</p>
        </div>
      </li>
      <li>
        <div
          class="icon"
          :style="{
            background:
              'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/02.png) no-repeat',
          }"
        ></div>
        <div class="text">
          <p class="h1">科学备考</p>
          <p class="h2">碎片化时间学习</p>
        </div>
      </li>
      <li>
        <div
          class="icon"
          :style="{
            background:
              'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/03.png) no-repeat',
          }"
        ></div>
        <div class="text">
          <p class="h1">班主任服务</p>
          <p class="h2">班主任7*12领学</p>
        </div>
      </li>
      <li>
        <div
          class="icon"
          :style="{
            background:
              'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/04.png) no-repeat',
          }"
        ></div>
        <div class="text">
          <p class="h1">正规机构</p>
          <p class="h2">职业教育机构</p>
        </div>
      </li>
      <li>
        <div
          class="icon"
          :style="{
            background:
              'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/05.png) no-repeat',
          }"
        ></div>
        <div class="text">
          <p class="h1">协议培训</p>
          <p class="h2">纸质版协议保障</p>
        </div>
      </li>
    </ul>
    <div class="footer">
      <div class="left">
        <div class="left_item">
          <div class="left_item_c">公司信息</div>
        </div>
        <div class="left_item">新手指南</div>
        <div class="left_item">帮助中心</div>
        <div class="left_item">售后服务</div>
      </div>
      <div class="right">
        <div class="right1"></div>
        <div class="right2"></div>
        <div class="right3">
          <p class="right3_1">
            <span class="icon"></span>
            (0371)61281118
          </p>
          <p class="right3_2">地址：郑州市高新区万达万华时光B座1612</p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom {
  background: #f3f2f8 no-repeat center;
  background-size: cover;
  padding: 76px 0 80px;
  background-image: url(https://static.wangxiao.cn/zhuanti/sem/shg/0325/img/footerbg.jpg);
  ul {
    width: 1000px;
    margin: 35px auto 50px;
    text-align: center;
    list-style: none;
    li {
      font-size: 0;
      text-align: left;
      margin: 0 4px;
      float: left;
      vertical-align: middle;
      .icon {
        vertical-align: middle;
        margin-right: 7px;
        display: inline-block;
        width: 62px;
        height: 62px;
        background: #fff;
        border-radius: 50%;
        // background: url('https://static.wangxiao.cn/zhuanti/sem/gw/img/01.png') no-repeat;
      }
      .text {
        vertical-align: middle;
        display: inline-block;
        .h1 {
          font-size: 20px;
          font-weight: 700;
          color: #db1d25;
        }
        .h2 {
          font-size: 16px;
          line-height: 30px;
          color: #666666;
        }
      }
    }
  }
  .footer {
    width: 100%;
    margin-top: 150px;
    font-size: 0;
    text-align: center;
    .left {
      width: 54%;
      box-sizing: border-box;
      padding: 0 10%;
      border-right: 1px solid #aaa;
      float: left;
      display: flex;
      justify-content: space-around;
      .left_item {
        font-size: 16px;
        width: 25%;
      }
    }
    .right {
      width: 46%;
      float: left;
      .right3 {
        vertical-align: middle;
        display: inline-block;
        margin-left: 200px;
        float: left;
        font-size: 14px;
      }
      .right3_1 {
        font-size: 18px;
        font-weight: 700;
        .icon {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url(https://static.wangxiao.cn/zhuanti/sem/gw/img/06.png);
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .right3_2 {
        font-size: 14px;
        color: #666;
        margin: 6px auto 16px;
      }
    }
  }
}
</style>