<template>
  <div class="content">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span :style="{ fontSize: '20px', fontWeight: 'bold' }">最新资讯</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="go_more(1)"
          >查看更多></el-button
        >
      </div>
      <div
        v-for="(item, index) in news"
        :key="index"
        class="text_item"
        @click="go_news(item.id)"
      >
        {{ item.title }}
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span :style="{ fontSize: '20px', fontWeight: 'bold' }">精选资料</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="go_more(2)"
          >查看更多></el-button
        >
      </div>
      <div v-for="(item, index) in information" :key="index" class="text_item">
        {{ item.title }}
      </div>
    </el-card>
  </div>
</template>

<script>
import { localStorageUtil } from "../../../LocalStorageUtil";
export default {
  data() {
    return {
      information: [
        {
          id: 1,
          title: "热门资料",
        },
        {
          id: 2,
          title: "考试大纲",
        },
        {
          id: 3,
          title: "电子书",
        },
        {
          id: 4,
          title: "音频听力",
        },
      ],
      news: [
        {
          id: 1,
          title: "2024年北京咨询工程师(投资)考前指导",
        },
        {
          id: 2,
          title: "湖南2024年咨询工程师(投资)准考证打印时间是4月9日至12日",
        },
        {
          id: 3,
          title: "2024年福建咨询工程师打印准考证时间：4月5日起",
        },
        {
          id: 4,
          title: "重要！2024年咨询工程师准考证打印时间及时查看",
        },
        {
          id: 5,
          title: "2024年北京咨询工程师准考证打印时间：4月9日至4月14日",
        },
        {
          id: 6,
          title: "最后一天！2024年注册咨询工程师报名入口即将关闭",
        },
      ],
    };
  },
  async created() {
    let res = await this.$axios("get_allNews",);
    console.log(res,111);
    this.news=res
  },
  methods: {
    go_more(index) {
      const token = localStorageUtil.readExpire("token");
      if (token && index==1) {
        this.$router.push({name:"Newslist"})
      } else if (token && index==2) {
      }else{
        this.$message("请先登录");
      }
    },
    go_news(id) {
      // const token = localStorageUtil.readExpire("token");
      // if (token) {
        this.$router.push({
          name: "News",
          query: {
            id: id,
          },
        });
      // } else {
      //   this.$message("请先登录");
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 40vh;
  box-sizing: border-box;
  padding: 50px 15%;
  display: flex;
  justify-content: space-between;
  .box-card {
    width: 48%;
    height: 100%;
    .el-card__header {
      .clearfix {
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          color: #6e6e6e;
        }
      }
    }
    .el-card__body {
      height: 20vh !important;
      .text_item {
        width: 100%;
        height: 4vh;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>