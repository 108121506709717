var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('ul',[_c('li',[_c('div',{staticClass:"icon",style:({
          background:
            'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/01.png) no-repeat',
        })}),_vm._m(0)]),_c('li',[_c('div',{staticClass:"icon",style:({
          background:
            'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/02.png) no-repeat',
        })}),_vm._m(1)]),_c('li',[_c('div',{staticClass:"icon",style:({
          background:
            'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/03.png) no-repeat',
        })}),_vm._m(2)]),_c('li',[_c('div',{staticClass:"icon",style:({
          background:
            'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/04.png) no-repeat',
        })}),_vm._m(3)]),_c('li',[_c('div',{staticClass:"icon",style:({
          background:
            'url(https://static.wangxiao.cn/zhuanti/sem/gw/img/05.png) no-repeat',
        })}),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"h1"},[_vm._v("业内老师")]),_c('p',{staticClass:"h2"},[_vm._v("行业名气讲师领学")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"h1"},[_vm._v("科学备考")]),_c('p',{staticClass:"h2"},[_vm._v("碎片化时间学习")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"h1"},[_vm._v("班主任服务")]),_c('p',{staticClass:"h2"},[_vm._v("班主任7*12领学")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"h1"},[_vm._v("正规机构")]),_c('p',{staticClass:"h2"},[_vm._v("职业教育机构")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',{staticClass:"h1"},[_vm._v("协议培训")]),_c('p',{staticClass:"h2"},[_vm._v("纸质版协议保障")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left_item"},[_c('div',{staticClass:"left_item_c"},[_vm._v("公司信息")])]),_c('div',{staticClass:"left_item"},[_vm._v("新手指南")]),_c('div',{staticClass:"left_item"},[_vm._v("帮助中心")]),_c('div',{staticClass:"left_item"},[_vm._v("售后服务")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right1"}),_c('div',{staticClass:"right2"}),_c('div',{staticClass:"right3"},[_c('p',{staticClass:"right3_1"},[_c('span',{staticClass:"icon"}),_vm._v(" (0371)61281118 ")]),_c('p',{staticClass:"right3_2"},[_vm._v("地址：郑州市高新区万达万华时光B座1612")]),_c('p')])])])
}]

export { render, staticRenderFns }