<template>
  <div class="index">
    <!-- <Top></Top> -->
    <Banner></Banner>
    <Card></Card>
    <Question></Question>
    <Bottom></Bottom>
  </div>

</template>

<script>
import Top from './components/Top.vue'
import Banner from './components/Banner.vue'
import Card from './components/Card.vue'
import Question from './components/Question.vue'
import Bottom from './components/Bottom.vue'
export default {
    name:'Index',
    components:{
        Top,
        Banner,
        Card,
        Question,
        Bottom
    },
}
</script>

<style lang="less" scoped>
.index{
  width: 100%;
  // height: 100vh;
  overflow:auto;
  background-color: #fafafa;
  height: auto;
    margin: auto;
}
</style>